import React from "react";
import { Layout } from "~/components";

import { AboutUs as AboutUsView } from "~/views";

const GovernmentWorks: React.FunctionComponent = () => (
  <Layout pageWidth={false} title="About Us">
    <AboutUsView />
  </Layout>
);

export default GovernmentWorks;
